import { format, parseISO } from 'date-fns'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line @typescript-eslint/naming-convention
interface ActivityStatusMiddlewareArgs {
	activity: {
		from: { role: string; name: string }
		timestamp: string
		text: string
	}
	sendState: unknown
	sameTimestampGroup: unknown
}
export default function useActivityStatusMiddleware(_botName: string) {
	const { t } = useTranslation()
    const [injectName , setInjectName] = useState('')
	return () => next =>
		function activityStatusMiddleware(args: ActivityStatusMiddlewareArgs) {
			const {
				activity: {
					from: { role, name },
					timestamp,
					text
				},
				sendState,
				// sameTimestampGroup,
			} = args
			if (role !== 'user' && text?.startsWith('#')) {
				args.activity.text = text?.replace(/[#]/g, '&#35;')
			}
            if(name && role === 'user')setInjectName(name)
			const isAnon = role === 'user' && (name?.toLowerCase() === 'anonymous user' || name?.toLowerCase() === 'you')
			const isUserWithName = name && role === 'user'
			const botName = isAnon ? t('You') : isUserWithName ? name : role === 'user' ? injectName :`${name || _botName} ` 

			if (sendState === 'sending') {
				return next(args)
				// return (
				//   <span className="activityStatus activityStatus__sendStatus">
				//     {t('Sending')}
				//   </span>
				// );
			}

			if (sendState === 'send failed') {
				return next(args)
				// return <span className="activityStatus">{t('Send failed.')}</span>;
			}

			return (
				<span
					className={`activityStatus activityStatus__timestamp ${role === 'user' ? 'from-user-botname' : 'from-bot-botname'
						}`}>
					<span className="activityStatus__timestampPretext">{botName}</span>
					<span className="activityStatus__timestampContent">
						{role === 'user' ? '' : `${t('at')} ${format(parseISO(timestamp), 'h:mm:ss')}`}
					</span>
				</span>
			)
		}
}
